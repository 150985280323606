<template>
  <div class="ds-wrapper relative md:w-8/12 md:max-w-2lg md:mx-auto">
    <div :class="`${isStatusSubmitted ? 'inactive-link yellow-border' : 'success-message'} text-center mt-32 bg-${isStatusSubmitted ? 'warning-500' : 'success-200'}-transparent pt-10 px-4 pb-8 sm:pt-14 sm:px-6 sm:pb-10 rounded-2xl sm:rounded-3xl`">
      <div :class="`check-box bg-${isStatusSubmitted ? 'warning' : 'success'}-500 h-16 w-16 sm:h-18 sm:w-18 flex justify-center items-center rounded-full absolute`">
        <vs-icon type="flat" :icon="isStatusSubmitted ? 'error_outline' : 'done'" class="text-shade-white" icon-size="large"></vs-icon>
      </div>
      <h2 class="mb-2 text-shade-black">{{ heading || (isStatusSubmitted ? "Nearly there …" : "All done!") }}</h2>
      <p class="mb-8 text-body-dark">Your payment reference is {{ paymentRequest.paymentRequestId }}.</p>
      <p class="mb-8 text-body-dark" v-html="message"></p>

      <div class="flex justify-center" v-if="requestOption == 'pay-later' && this.paymentStatus === 'Active'">
        <div class="flex items-center gap-x-10 bg-neutral-50 rounded-lg py-1.5 px-3">
          <p class="text-interaction-primary text-left">
            <a @click="redirect" class="no-underline">{{ customerPortalLink }}</a>
          </p>
          <vs-icon icon="file_copy" @click="copyPortalLinkToClipboard" class="material-icons material-icons-outlined cursor-pointer" icon-size="medium"></vs-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: ["customerPortal", "heading", "message", "paymentRequest", "requestOption", "paymentStatus"],

  computed: {
    paymentRequestId () {
      return this.paymentRequest && this.paymentRequest.paymentRequestId ? this.paymentRequest.paymentRequestId.replace("PR-", "") : "";
    },
    customerPortalLink() {
      return `${this.customerPortal}payment-request/${this.paymentRequestId}`;
    },
    isStatusSubmitted() {
      return (this.paymentStatus === "Submitted");
    }
  },

  methods: {
    copyPortalLinkToClipboard() {
      navigator.clipboard.writeText(this.customerPortalLink);
    },
    redirect() {
      window.open(this.customerPortalLink, "_blank");
    }
  }
}
</script>

