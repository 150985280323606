<template>
  <div class="ds-wrapper">
    <div class="bg-container-40 pt-3 px-4 pb-6 md:pt-4 md:px-6 md:pb-8 rounded-t-3xl rounded-b-2xl md-rounded-3xl">
      <div v-if="paymentRequest.paymentPage && paymentRequest.paymentPage.pageBranding && paymentRequest.paymentPage.pageBranding.companyName" class="flex items-center justify-between mb-8">
        <div>
          <p class="text-ancillary text-body pb-1">From</p>
          <p class="text-primary">{{ paymentRequest.paymentPage.pageBranding.companyName }}</p>
        </div>
      </div>

      <div class="grid gap-y-6">
        <div>
          <p class="text-primary text-ancillary mb-1">Reference</p>
          <p class="text-body">{{ paymentRequest.reference }}</p>
        </div>
        <div v-if="paymentRequest.message">
          <p class="text-primary text-ancillary mb-1">Message</p>
          <p class="text-body">{{ paymentRequest.message }}</p>
        </div>
        <div class="grid" v-if="paymentRequest.files && paymentRequest.files.length">
          <p class="text-primary text-ancillary mb-1.5">Attached files</p>
          <template v-for="(file, key) in paymentRequest.files">
            <p :key="key" class="mb-1.5 text-interaction-primary">
              <S3FileGenerator :document="file.name" :key="file.name"/>
            </p>
          </template>
        </div>
        <div class="grid gap-y-4" v-if="paymentRequest.paymentPage && paymentRequest.paymentPage.pageBranding">
          <div v-if="paymentRequest.paymentPage && paymentRequest.paymentPage.pageBranding && paymentRequest.paymentPage.pageBranding.companyName">
            <p class="text-primary text-ancillary mb-1">Request sender</p>
            <div class="flex justify-between items-center mt-1">
              <p class="text-body">{{ paymentRequest.paymentPage.pageBranding.companyName }}</p>
              <vs-icon @click="showMore = !showMore" class="cursor-pointer text-xl text-body" :icon="!showMore ? 'expand_more' : 'expand_less'"></vs-icon>
            </div>
          </div>
          <div class="grid gap-y-4" v-if="showMore">
            <div class="flex gap-x-3 items-center" v-if="paymentRequest.paymentPage.pageBranding.companyAbn">
              <svg width="19" height="8" viewBox="0 0 19 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.62925 5.98196H2.12725L1.71325 7.17896H0.39025L2.64925 0.887955H4.11625L6.37525 7.17896H5.04325L4.62925 5.98196ZM4.28725 4.97396L3.37825 2.34596L2.46925 4.97396H4.28725ZM10.7836 3.95695C11.1376 4.02296 11.4286 4.19995 11.6566 4.48795C11.8846 4.77595 11.9986 5.10595 11.9986 5.47795C11.9986 5.81396 11.9146 6.11096 11.7466 6.36896C11.5846 6.62096 11.3476 6.81896 11.0356 6.96296C10.7236 7.10696 10.3546 7.17896 9.92863 7.17896H7.21963V0.896955H9.81163C10.2376 0.896955 10.6036 0.965955 10.9096 1.10395C11.2216 1.24195 11.4556 1.43396 11.6116 1.67996C11.7736 1.92595 11.8546 2.20495 11.8546 2.51696C11.8546 2.88295 11.7556 3.18895 11.5576 3.43496C11.3656 3.68095 11.1076 3.85495 10.7836 3.95695ZM8.47963 3.48895H9.63163C9.93163 3.48895 10.1626 3.42295 10.3246 3.29095C10.4866 3.15295 10.5676 2.95795 10.5676 2.70595C10.5676 2.45395 10.4866 2.25895 10.3246 2.12095C10.1626 1.98295 9.93163 1.91395 9.63163 1.91395H8.47963V3.48895ZM9.74863 6.15296C10.0546 6.15296 10.2916 6.08095 10.4596 5.93695C10.6336 5.79296 10.7206 5.58896 10.7206 5.32495C10.7206 5.05495 10.6306 4.84495 10.4506 4.69495C10.2706 4.53895 10.0276 4.46095 9.72163 4.46095H8.47963V6.15296H9.74863ZM18.3758 7.17896H17.1158L14.2628 2.86795V7.17896H13.0028V0.887955H14.2628L17.1158 5.20795V0.887955H18.3758V7.17896Z" fill="#AAAAAA"/>
              </svg>
              <p class="text-body">{{ paymentRequest.paymentPage.pageBranding.companyAbn }}</p>
            </div>
            <div class="flex gap-x-3 items-center" v-if="paymentRequest.paymentPage.pageBranding.companyWeb">
              <vs-icon class="text-neutral-500" icon="language"></vs-icon>
              <p class="text-body">{{ paymentRequest.paymentPage.pageBranding.companyWeb }}</p>
            </div>
            <div class="flex gap-x-3 items-center" v-if="paymentRequest.paymentPage.pageBranding.companyAddress">
              <vs-icon class="text-neutral-500 material-icons-outlined" icon="location_on"></vs-icon>
              <p class="text-body">{{ paymentRequest.paymentPage.pageBranding.companyAddress }}</p>
            </div>
            <div class="flex gap-x-3 items-center" v-if="paymentRequest.paymentPage.pageBranding.companyPhone">
              <vs-icon class="text-neutral-500 material-icons-outlined" icon="call"></vs-icon>
              <p class="text-body">{{ paymentRequest.paymentPage.pageBranding.companyPhone }}</p>
            </div>
            <div class="flex gap-x-3 items-center" v-if="paymentRequest.paymentPage.pageBranding.companyEmail">
              <vs-icon class="text-neutral-500 material-icons-outlined" icon="mail"></vs-icon>
              <p class="text-body">{{ paymentRequest.paymentPage.pageBranding.companyEmail }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import S3FileGenerator from "@/views/components/S3FileGenerator";

export default {
  name: "RequestHeader",
  props: ["paymentRequest"],

  components: {
    S3FileGenerator,
  },

  data() {
    return {
      showMore: false
    }
  }
};
</script>

