<template>
  <div class="bg-container-40 pt-4 px-4 pb-6 md:px-6 md:pb-8 rounded-2xl md-rounded-3xl">
    <div class="flex justify-between items-center mb-4">
      <h4>Payment option</h4>
      <vs-icon type="flat" class="text-body-dark material-icons-outlined cursor-pointer" icon="expand_less" icon-size="large" />
    </div>
    <p class="mb-2">{{ requestOption === "pay-now" ? "Pay today" : selectedPlan.productName }}</p>
    <div class="md:flex md:items-center">

      <p class="para-def-light mb-6 md:mb-0" v-if="requestOption === 'pay-now'">
        {{ `$${addZeroes(amount)} + transaction fees` }}
      </p>
      <p class="para-def-light mb-6 md:mb-0" v-else-if="requestOption === 'pay-later' && selectedPlan && selectedPlan.currentPlan && selectedPlan.currentPlan.productType === 'B2C_BNPL'">
        {{ `${selectedPlan.numberOfPayments} ${selectedPlan.paymentFrequency.toLowerCase()} payments of $${getInstallmentAmount}` }}
      </p>
      <p v-else class="para-def-light mb-6 md:mb-0">
        {{ `$${getTotalAmount} due ${getMaturityDate}` }}
      </p>

      <vs-button type="border" size="small" class="flex ml-auto" @click="changeSelectedOption">Change</vs-button>
    </div>
  </div>
</template>

<script>
  import moment from "moment";

  export default {
    props: ["amount", "requestOption", "selectedPlan", "transactions"],

    methods: {
      changeSelectedOption() {
        this.$emit("changeSelectedOption");
      },

      addZeroes(num) {
        num = String(num);
        return Number(num).toLocaleString(undefined, {
          minimumFractionDigits: 2,
        });
      },

      formatScheduleDate(date) {
        return moment(date).format("DD MMM YYYY");
      },
    },

    computed: {
      getInstallmentAmount() {
        let schedule = this.transactions.filter(item => item.type === "Payment");
        schedule = schedule.sort(function(first, second) { return first.amount - second.amount });
        return this.addZeroes(schedule && schedule.length ? schedule[schedule.length - 1].amount : 0);
      },

      getTotalAmount() {
        const total = this.transactions.find(item => item.type === "Total");
        return this.addZeroes(total ? total.amount : 0);
      },

      getMaturityDate() {
        const schedule = this.transactions.filter(item => item.type === "Payment");
        return this.formatScheduleDate(schedule[schedule.length -1].date);
      },
    }
  }
</script>

