<template>
  <div>
    <h2 class="mt-6 text-primary uppercase text-center">your contact details</h2>
    <div class="mt-6 text-center">
      <template  v-if="requestOption == 'pay-now'">
        <p class="text-body-dark">Your contact details are required to secure your form - you can come back to complete it at any time without losing progress.</p>
        <br />
        <p class="text-body-dark">We'll also send important communications regarding your payment plan. Don’t worry, we won’t spam you.</p>
      </template>
      <template v-else>
        <p class="text-body-dark" v-html="selectedPlan.contactDetailMessage"></p>
      </template>
    </div>
    <template v-if="!phoneOtp">
      <div class="ds-wrapper grid gap-y-8 md:grid-cols-2 md:gap-x-20 mt-14 md:mt-16">
        <div>
          <vs-input label="Email Address" placeholder="Required" v-model="email" v-validate="'required|email'" name="email" data-vv-as="email" />
          <span class="text-danger text-sm" v-show="errors.has('email')">{{ errors.first("email") }}</span>
        </div>
        <div class="input-datepicker">
          <label for="mobile" class="input-label">Mobile Number</label>
          <the-mask
            :class="mobile ? 'hasValue phone-w' : 'phone-w'"
            class="vs-input--input w-full datepicker-input mask-input"
            :mask="['#### ### ###']"
            v-model="mobile"
            masked
            placeholder="Enter your mobile number"
            name="mobileNumber"
            data-vv-as="mobile number"
            v-validate="{ required: true, regex: /^04\(?\d{2}\)?[\s.-]\d{3}[\s.-]\d{3}$/,}"
          />
          <span class="text-danger text-sm" v-show="errors.has('mobileNumber')">{{ errors.first("mobileNumber") }}</span>
        </div>
      </div>
      <div class="flex justify-center mt-14">
        <vs-button v-if="!phoneOtp" color="primary" size="large" class="w-full max-w-400" @click.prevent="toggleOtpField">Continue to: Verify mobile number</vs-button>
      </div>
    </template>
    <template v-else>
      <div>
        <div class="text-center">
          <p class="mb-8">Please enter the 6-digit code we just sent to <br /> {{ mobile }}.</p>
          <div class="otp-input-wrap flex justify-center mb-4">
            <v-otp-input
              :class="{ 'input-validate-danger': isOtpInvalid }"
              ref="otpInput1"
              input-classes="otp-input"
              separator=""
              :num-inputs="6"
              :should-auto-focus="true"
              input-type="number"
              @on-change="handleOnChange"
              @on-complete="handleOnComplete"
            />
          </div>
          <span v-if="isOtpInvalid" class="text-sm text-danger">Verification code invaild. Please try again.</span>
          <p class="text-sm text-body mt-2">Didn't get it?</p>
          <p class="text-sm mb-14">
            <span @click="sendVerificationCode" class="text-interaction-primary cursor-pointer">Resend code</span>
            <span class="text-body-dark mx-1">or</span>
            <span @click="changeNumber" class="text-interaction-primary cursor-pointer">Change mobile number</span>
          </p>
          <vs-button @click="verifyMobile" color="primary" size="large" class="w-full max-w-400" :disabled="!isOtpComplete">Continue to: {{ buttonText }}</vs-button>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
  import { mapActions } from "vuex";
  import { TheMask } from "vue-the-mask";
  import OtpInput from "@bachdgvn/vue-otp-input";

  export default {
    data() {
      return {
        code: "",
        email: "",
        isOtpComplete: false,
        mobile: "",
        phoneOtp: false,
        isOtpInvalid: false,
      }
    },

    props: ["buttonText", "requestOption", "paymentRequest", "selectedPlan"],

    components: {
      "v-otp-input": OtpInput,
      TheMask
    },

    methods: {
      ...mapActions("payment", ["sendDeviceVerificationCodeForPaylater", "verifyDeviceForPaylater"]),

      async toggleOtpField() {
        const valid = await this.$validator.validate();

        if (!valid) {
          return;
        }
        let payload = {
          paymentRequestId: this.paymentRequest._id,
          mobile: this.mobile.split(" ").join(""),
        };

        this.sendDeviceVerificationCodeForPaylater(payload).then((_response) => {
          this.$vs.loading.close();
          this.phoneOtp = true;
        }).catch((ex) => {
          this.$vs.loading.close();
          this.showErrorMessage("Verification", ex.data.message);
        });

        this.phoneOtp = !this.phoneOtp;
      },

      handleOnComplete(value) {
        this.code = value;
        this.isOtpComplete = true;
      },

      handleOnChange(value) {
        this.code = value;
        this.isOtpComplete = (value && value.length == 6);
      },

      verifyMobile() {
        if (this.code === "") {
          this.showWarningMessage('Verification', "Please enter verification code");
        }

        this.$vs.loading();

        let deviceVerificationData = {
          paymentRequestId: this.paymentRequest._id,
          email: this.email,
          mobile: this.mobile.split(" ").join(""),
          code: this.code,
        };

        this.verifyDeviceForPaylater(deviceVerificationData).then((_response) => {
          this.$vs.loading.close();
          this.phoneOtp = false;
          this.$emit("mobileVerificationCompleted", { email: this.email, mobile: this.mobile });
        }).catch((ex) => {
          this.isOtpInvalid = true;
          this.$vs.loading.close();
        });
      },

      showErrorMessage(title = "Error", message = "Something went wrong.", time = false){
        this.$toast.error(<div class="container"><h3>{title}</h3><p>{message}</p></div>, {
          position: "top-right",
          timeout: time ? 3000 : false,
          closeOnClick: false,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: false,
          draggablePercent: 0.1,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: {
            iconClass: "material-icons text-danger",
            iconChildren: "highlight_off",
            iconTag: "span"
          },
          rtl: false,
          message: message
        });
      },

      sendVerificationCode() {
        this.$vs.loading();
        let deviceVerificationData = {
          paymentRequestId: this.paymentRequest._id,
          mobile: this.mobile.split(" ").join(""),
        };

        this.sendDeviceVerificationCodeForPaylater(deviceVerificationData).then((_response) => {
          this.$vs.loading.close();
        }).catch((ex) => {
          this.$vs.loading.close();
          this.showErrorMessage("Verification", ex.data.message);
        });
      },

      changeNumber() {
        this.isOtpInvalid = false;
        this.phoneOtp = false;
      }
    },

    mounted() {
      this.email = this.paymentRequest.payeeEmail || "";
      this.mobile = this.paymentRequest.payeePhone || "";
    }
  }
</script>

