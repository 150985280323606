<template>
  <div class="mt-3" v-if="localData.type == 'EFT' && localData.disburseTo == 'external'">
    <h2 class="text-center text-primary uppercase mb-6">{{ localData.sectionHeading }}</h2>
    <p class="text-center text-body-dark mb-10" v-if="localData.showDescription">{{ localData.description }}</p>

    <vs-row class="mb-4" vs-w="12" vs-align="flex-start" vs-type="flex" vs-justify="center">
      <vs-col vs-md="6" vs-sm="12" class="max-w-420">
        <div class="w-full">
          <a @click="resetToDefault()" class="no-underline text-interaction-primary text-right block text-sm">
            <vs-icon icon-size="small" class="refresh-icon">refresh</vs-icon> Reset to default
          </a>
        </div>
      </vs-col>
    </vs-row>
    <vs-row class="" vs-w="12" vs-align="flex-start" vs-type="flex" vs-justify="center">
      <vs-col vs-md="6" vs-sm="12" class="max-w-420">
        <div class="w-full">
          <vs-input
            name="disbursementAccountName"
            label="Account name" v-model="localData.accountName"
            icon="done"
            icon-after="true"
            icon-size="small"
            v-validate="'required'"
            maxlength="32"
            data-vv-as="account name"
            autocomplete="off"
            :success="accountNameValid"
            :class="errors.has('disbursementAccountName') ? 'hasError' : ''"
            @change="checkFieldValidation('disbursementAccountName')"
            @blur="updatedValues(true)"
          />
          <span class="span-text-validation-danger" v-if="errors.has('disbursementAccountName')"><span class="span-text-validation">{{ errors.first("disbursementAccountName") }}</span></span>
        </div>
        <div class="flex flex-row gap-x-6 my-8">
          <div class="w-2/5">
            <vs-input
              data-vv-as="BSB"
              label="BSB"
              name="disbursementBsb"
              :class="!bsbError ? 'hasError' : ''"
              placeholder="000 - 000"
              v-mask="'###-###'"
              v-model="localData.accountBsb"
              :success="bsbSuccess"
              icon="done"
              icon-after="true"
              icon-size="small"
              v-validate="'required'"
              @blur="handleBsb"
              autocomplete="off"
              @change="updatedValues(true)"
            />
            <span class="span-text-validation-danger" v-if="errors.has('disbursementBsb')"><span class="span-text-validation">{{ errors.first("disbursementBsb") }}</span></span>
            <span class="span-text-validation-danger" v-if="!showBsbValid"><span class="span-text-validation">Invalid BSB</span></span>
          </div>
          <div class="w-3/5">
            <vs-input
              name="disbursementAccountNumber"
              label="Account number"
              v-model="localData.accountNumber"
              icon="done"
              icon-after="true"
              icon-size="small"
              v-validate="'required|alphanumFormat'"
              data-vv-as="account number"
              autocomplete="off"
              :success="accountNumberValid"
              :class="errors.has('disbursementAccountNumber') ? 'hasError' : ''"
              @change="checkFieldValidation('disbursementAccountNumber')"
              @blur="updatedValues(true)"
            />
            <span class="span-text-validation-danger" v-if="errors.has('disbursementAccountNumber')"><span class="span-text-validation">{{ errors.first("disbursementAccountNumber") }}</span></span>
          </div>
        </div>
        <div class="w-full mb-8">
          <label class="input-label">
            Reference
            <popper trigger="hover" :options="{ placement: 'top', modifiers: { offset: { offset: '-55px, 2px' }}}">
              <template slot="reference" class="top"><ToolTipIcon width="12px" height="12px" class="vs-icon" /></template>
              <div class="popper py-2 px-4 text-center">
                <p class="text-primary">Please do not add special characters.</p>
              </div>
            </popper>
          </label>
          <vs-input
            name="reference"
            v-model="localData.reference"
            maxlength="280"
            v-validate="'alphaNumSpace'"
            data-vv-as="reference"
            data-vv-validate-on="blur"
            :class="errors.has('reference') ? 'hasError' : ''"
            @keypress="handleReferenceKeyPress($event)"
            autocomplete="off"
          />
          <span class="span-text-validation-danger" v-if="errors.has('reference')">{{ errors.first("reference") }}</span>
        </div>
        <div>
          <vs-button  color="primary" :disabled="buttonDisabled" @click="submitDisbursement()" class="w-full mb-4" size="large"> Continue to: Payment method </vs-button>
        </div>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import { mask } from "vue-the-mask";
import { mapActions } from "vuex";
import Popper from 'vue-popperjs';
import ToolTipIcon from "../../components/design-sys-components/icons/ToolTipIcon";

export default {
  name: "Disbursement",
  props: ["checkCircle", "disbursement", "paymentRequestObjectId"],
  components: { Popper, ToolTipIcon },
  directives: {
    mask
  },
  data() {
    return {
      accountNameValid: true,
      accountNumberValid: true,
      bsbError: true,
      bsbSuccess: false,
      localData: {},
      showBsbValid: true,
      showHeading: false,
    }
  },
  computed: {
    buttonDisabled() {
      return (this.errors.items.length || !this.showBsbValid || !this.accountNameValid);
    }
  },
  methods: {
    ...mapActions("payment", ["updateDisbursement"]),

    resetToDefault() {
      this.emitData({ resetToDefault: true });
      this.updatedValues(false);
    },

    submitDisbursement() {
      this.checkFieldValidation("disbursementAccountNumber");
      this.checkFieldValidation("disbursementAccountName");
      this.handleBsb();

      if (this.errors.items.length) {
        return false;
      }

      let payload = {
        paymentRequestId: this.paymentRequestObjectId,
        data: {
          accountNumber: this.localData.accountNumber,
          accountBsb: this.localData.accountBsb,
          accountName: this.localData.accountName,
          type: this.localData.type,
          disburseTo: this.localData.disburseTo,
          reference: this.localData.reference,
          updatedByMerchant: this.localData.updatedByMerchant,
          updatedByCustomer: this.localData.updatedByCustomer
        },
      };

      this.$vs.loading();

      this.updateDisbursement(payload).then(result => {
        this.$vs.loading.close();

        if (result) {
          this.emitData({ submitDisbursement: true });
        }
      }).catch(ex => {
        this.$vs.loading.close();
        this.showErrorMessage(ex.data.title, ex.data.message);
      });
    },

    emitData(data) {
      this.$emit("updateDisbursement", data);
    },
    handleBsb() {
      const bsbData = this.localData.accountBsb.split("-");
      this.showBsbValid = (bsbData.length == 2 && bsbData[1].length == 3);
      this.bsbSuccess = this.showBsbValid;
      this.bsbError = this.showBsbValid;
    },

    checkFieldValidation(field, submit = true) {
       if (field == "disbursementAccountName") {
        this.accountNameValid = this.localData.accountName.length > 1;

        if (submit && !this.accountNameValid) {
          this.errors.add({
            field: "disbursementAccountName",
            msg: "Please enter a valid account name"
          });
        }
      } else if (field == "disbursementAccountNumber") {
        this.accountNumberValid = this.localData.accountNumber.length > 1 && !this.errors.has("disbursementAccountNumber");

        if (submit && !this.accountNumberValid) {
          this.errors.add({
            field: "disbursementAccountNumber",
            msg: "Please enter a valid account number"
          });
        }
      }
    },

    showErrorMessage(title = 'Error', message='Something went wrong.', time = false){
      this.$toast.error(<div class="container"><h3>{title}</h3><p>{message}</p></div>, {
        position: "top-right",
        timeout: time ? 3000 : false,
        closeOnClick: false,
        pauseOnFocusLoss: false,
        pauseOnHover: false,
        draggable: false,
        draggablePercent: 0.1,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: {
          iconClass: "material-icons text-danger",
          iconChildren: "highlight_off",
          iconTag: "span"
        },
        rtl: false,
        message: message
      });
    },

    handleReferenceKeyPress(event) {
      const charCode = event.which || event.keyCode;

      // Check if the pressed key corresponds to a special character
      if (charCode >= 33 && charCode <= 47 ||
          charCode >= 58 && charCode <= 64 ||
          charCode >= 91 && charCode <= 96 ||
          charCode >= 123 && charCode <= 126) {
          event.preventDefault();
      }
    },

    updatedValues(val) {
      this.localData.updatedByCustomer = val;
    }
  },

  mounted() {
    this.localData = this.disbursement;
    this.checkFieldValidation("disbursementAccountNumber", false);
    this.checkFieldValidation("disbursementAccountName", false);
  },

  watch: {
    disbursement(val) {
      if (val) {
        this.localData = val;
        this.checkFieldValidation("disbursementAccountNumber");
        this.checkFieldValidation("disbursementAccountName");
        this.handleBsb();
      }
    }
  }
}
</script>

