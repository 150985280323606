<template>
  <vs-card class="bg-container-40 mt-4 md:mt-6 py-6 px-6 pb-8 text-center sm:text-justify md:w-8/12 md:max-w-2lg md:mx-auto rounded-2xl sm:rounded-3xl" v-if="redirectData.success.header || redirectData.success.body || (redirectData.success.url && redirectData.success.btnText)">
    <div class="flex justify-center mb-4" v-if="redirectData.success.header">
      <h3 class="font-medium text-lg md:text-xl">{{ redirectData.success.header }}</h3>
    </div>
    <div class="flex justify-center mb-6 md:mb-8" v-if="redirectData.success.body">
      <p class="text-body-dark">{{ redirectData.success.body }}</p>
    </div>
    <vs-row v-if="redirectData.success.url && redirectData.success.btnText">
      <vs-col class="text-center mb-2 md:mb-4">
        <vs-button class="px-8 min-w-1/2" v-round size="large" @click="actionButtonClick()">
          {{ redirectData.success.btnText }}
        </vs-button>
        <p v-if="timerEnabled && timer > 0" class="text-body-dark mt-6 mb-4">{{ `Redirecting you in ${timer} seconds ...` }}</p>
      </vs-col>
    </vs-row>
  </vs-card>
</template>

<script>
export default {
  data() {
    return {
      timer: 0
    };
  },

  name: "successUrl",

  props: {
    redirectData: {
      type: Object
    },
    timerCount: {
      type: Number,
      default: 60
    },
    timerEnabled: {
      type: Boolean,
      default: false
    },
  },

  methods: {
    actionButtonClick() {
      const val = (this.redirectData.success && this.redirectData.success.url) ? this.redirectData.success.url : null;

      if (val && (val.indexOf("http://") == 0 || val.indexOf("https://") == 0)) {
        window.open(val, "_blank");
      } else if (val) {
        window.open("http://" + val, "_blank");
      }
    },

    countDownTimer () {
      if (this.timerEnabled && this.timer == 0) {
        this.actionButtonClick();
      } else if (this.timerEnabled && this.timer > 0) {
        setTimeout(() => {
          this.timer -= 1;
          this.countDownTimer();
        }, 1000);
      }
    },
  },

  mounted() {
    this.timer = (typeof this.timerCount == "string") ? parseInt(this.timerCount) : this.timerCount;

    if (this.redirectData && this.redirectData.success && this.redirectData.success.url) {
      this.countDownTimer();
    }
  }
}
</script>

