<template>
  <div class="flex flex-col items-center">
    <div class="dashed-border m-auto my-4 w-full max-w-420"></div>
    <div class="flex justify-between items-center w-full max-w-420 mb-4">
      <p class="text-primary">{{ selectedPlan && selectedPlan.summaryHeader ? selectedPlan.summaryHeader : "Your agreement" }}</p>
    </div>
    <div class="flex justify-between items-center mb-4 w-full max-w-420">
      <p>{{ selectedPlan && selectedPlan.summaryAmountLabel ? selectedPlan.summaryAmountLabel : "Purchase amount" }}</p>
      <p class="text-sm">$ {{ addZeroes(amount) }}</p>
    </div>
    <div class="flex justify-between items-center w-full max-w-420">
      <p>{{ getFeeText }}</p>
      <p class="text-sm">$ {{ addZeroes(feeAmount) }}</p>
    </div>
    <div class="total-amt"></div>
    <div class="flex justify-between items-center w-full max-w-420 mt-6">
      <p class="text-primary">{{ selectedPlan && selectedPlan.summaryTotalLabel ? selectedPlan.summaryTotalLabel : "Total" }}</p>
      <p class="text-primary">$ {{addZeroes(total) }}</p>
    </div>
    <div v-if="getSummaryMessage" class="mt-5">
      <p>{{ getSummaryMessage }}</p>
    </div>
    <div class="mt-5" v-if="selectedPlan.summaryConfigurableFeeText">
      <p>{{ selectedPlan.summaryConfigurableFeeText }}</p>
    </div>
    <div class="w-full max-w-420 bg-container-40 mt-8 rounded-2xl" v-if="selectedPlan.productType == 'B2C_BNPL'">
      <div class="flex justify-between px-8 pt-4 pb-3">
        <h3 class="text-body-dark ancillary uppercase">{{ showPaymentDetail ? "hide repayment schedule" : "show repayment schedule" }}</h3>
        <vs-icon type="flat" class="text-body-dark material-icons-outlined cursor-pointer" :icon="showPaymentDetail ? 'expand_less' : 'expand_more'" icon-size="large" @click="showPaymentDetail = !showPaymentDetail" />
      </div>
        <div class="md:col-start-3 md:col-end-11 bg-white px-8 py-3 schedule-body" v-if="showPaymentDetail">
          <div class="flex justify-between">
            <h5>{{ selectedPlan.dateLabel || "Date" }}</h5>
            <h5>{{ selectedPlan.amountLabel || "Amount" }}</h5>
          </div>
          <hr class="mt-2.5 mb-4" />
          <div class="grid max-h-40 overflow-y-auto pr-1">
            <div v-for="(schedule, key) in scheduledTransaction" :key="key" class="flex justify-between mb-1.5">
              <p>{{ key === 0 ? getInitialDate(schedule.date) : formatScheduleDate(schedule.date) }}</p>
              <p>$ {{ addZeroes(schedule.amount) }}</p>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
  import moment from "moment";

  export default {
    props: ["amount", "feeAmount", "requestOption", "selectedPlan", "total", "transactions"],
    data() {
      return {
        showPaymentDetail: false,
      }
    },

    methods: {
      addZeroes(num) {
        num = String(num);
        return Number(num).toLocaleString(undefined, {
          minimumFractionDigits: 2,
        });
      },

      formatScheduleDate(date) {
        return moment(date).format("DD MMM YYYY");
      },

      getFirstTransactionAmount() {
        let schedule = this.transactions.filter(item => item.type == "Payment");
        schedule = schedule.sort(function(first, second) { return first.amount - second.amount });
        return schedule && schedule.length ? this.addZeroes(schedule[schedule.length - 1].amount) : 0.00;
      },

      getInitialDate(date) {
        return moment(moment().format("YYYY-MM-DD")).isSame(date) ? "Today" : this.formatScheduleDate(date);
      }
    },

    computed: {
      getFeeText() {
        let text = "";

        if (this.requestOption == "pay-now") {
          text = "Card surcharge";
        } else {
          text = this.selectedPlan && this.selectedPlan.summaryFeeLabel ? this.selectedPlan.summaryFeeLabel : "Service fee";
        }

        return text;
      },

      getSummaryMessage() {
        if (this.selectedPlan && ["B2C_BNPO", "B2C_BNPO_NC"].includes(this.selectedPlan.productType)) {
          let message = this.selectedPlan.summaryMessage || "Our share of proceeds will be deducted on";
          const schedule = this.transactions.filter(item => item.type == "Payment");

          return `${message} ${this.formatScheduleDate(schedule[schedule.length - 1].date)}`;
        }
      },

      scheduledTransaction() {
        return this.transactions.filter(item => item.type === "Payment" && item.status == "Scheduled");
      },
    },
  }
</script>

