<template>
  <div class="ds-wrapper relative md:w-8/12 md:max-w-2lg md:mx-auto">
    <div class="inactive-link yellow-border text-center mt-12 bg-warning-500-transparent pt-10 px-4 pb-8 sm:pt-14 sm:px-6 sm:pb-10 rounded-2xl sm:rounded-3xl">
      <div class="check-box bg-warning-500 h-16 w-16 sm:h-18 sm:w-18 flex justify-center items-center rounded-full absolute">
        <vs-icon type="flat" icon="sentiment_dissatisfied" class="text-shade-white" icon-size="large"></vs-icon>
      </div>
      <h2 class="mb-2 text-shade-black">Oops!</h2>
      <p class="mb-8 text-body-dark">{{ message }}</p>
    </div>
  </div>
</template>

<script>

  export default {
    props: ["message"],
    data() {
      return {

      }
    }
  }
</script>
