<template>
  <div class="mt-4 md:mt-6 md:py-2 text-center sm:text-justify rounded-3xl bg-container-40" v-if="redirectData.fail.url != '' && redirectData.fail.btnText != '' && !redirectData.fail.enablepaynow">
    <div class="flex justify-center" v-if="redirectData.fail.header">
      <h3 class="font-medium text-lg md:text-xl pt-6">{{ redirectData.fail.header }}</h3>
    </div>
    <p class="text-center font-light text-base text-body leading-normal pt-6" v-if="redirectData.fail.body">
      {{ redirectData.fail.body }}
    </p>


    <div class="sm:grid sm:grid-cols-12 sm:items-center mt-10" v-if="redirectData.fail.url && redirectData.fail.btnText">
      <vs-button size="large" class="w-full sm:col-start-5 sm:col-end-9 mb-8" @click="actionButtonClick(redirectData.fail.url)">{{ redirectData.fail.btnText }}</vs-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "FailureUrl",

  props: ["redirectData"],

  methods:{
    actionButtonClick(val) {
      if (val.indexOf("http://") == 0 || val.indexOf("https://") == 0) {
        window.open(val, "_blank");
      } else {
        window.open("http://" + val, "_blank");
      }
    }
  }
}
</script>

