<template>
  <div class="mt-3">
    <h2 class="text-center text-primary uppercase mb-6">Required information</h2>
    <p v-if="selectedPlan.requiredInformationText" class="text-center text-body-dark mb-10" v-html="selectedPlan.requiredInformationText"></p>

    <div class="grid gap-y-6 gap-x-6 md:grid-cols-2 md:gap-x-20" v-if="inputArray.length > 0">
      <template v-for="(customField, key) in inputArrayFiltered">
        <div
          :key="key + 0.6"
          class="add-info input-datepicker"
          v-if="
            customField.type !== 'address' &&
            customField.type !== 'file' &&
            (customField.editableByCustomer  ||
              customField.requiredToSubmit ||
              (customField.displayToCustomer &&
                Object.keys(customField.value.toString()).length > 0))">
          <label style="color: #050540" :class="errors.has(`${customField.label}`) ? 'text-sm md:text-base text-danger input-label' : 'text-sm md:text-base font-light input-label'">
            {{ customField.label }}
            <span v-if="customField.requiredToSubmit" class="mid-blue">*</span>
            <span v-if="customField.description">
              <vs-tooltip :text="customField.description" position="top" class="icon-tooltip">
                <img class="icon-control" :src="tooltip" alt="tooltip" />
              </vs-tooltip>
            </span>
          </label>

          <vs-input
            v-if="customField.type !== 'currency' && customField.type !== 'dropdown' && customField.type !== 'date' && (customField.editableByCustomer || customField.requiredToSubmit) "
            v-validate="{
              required: customField.requiredToSubmit ? true : false,
              decimal: customField.type == 'number' ? true : false,
            }"
            :name="`${customField.label}`"
            :type="customField.type"
            v-model="customField.value"
            @keypress="(customField.type == 'number')?isNumber($event):''"
            :class="errors.has(`${customField.label}`) ? 'border-danger' : ''"
            @change="assignFieldValue(customField._id, customField.value)"
            :disabled="!customField.editableByCustomer"/>
          <money
            v-else-if="customField.type === 'currency' && (customField.editableByCustomer || customField.requiredToSubmit)"
            v-validate="{customCurrency: customField.requiredToSubmit ? true : false}"
            :name="`${customField.label}`"
            v-model="customField.value"
            :class="errors.has(`${customField.label}`) ? 'border-danger datepicker-input w-full' : 'datepicker-input w-full'"
            class="vs-input--input"
            v-bind="moneyMask"
            @change.native="assignFieldValue(customField._id, customField.value)"
            />
          <vs-select
            v-validate="{required: customField.requiredToSubmit ? true : false}"
            class="m-0 mob-w rounded-none"
            :class="errors.has(`${customField.label}`) ? 'border-danger selectExample w-full' : 'selectExample w-full'"
            v-model="customField.value"
            :name="`${customField.label}`"
            v-else-if="customField.type === 'dropdown' && (customField.editableByCustomer || customField.requiredToSubmit)"
            @change="assignFieldValue(customField._id, customField.value)"
            >
            <vs-select-item
              :key="i"
              :value="customField"
              :text="customField"
              v-for="(customField, i) in customField.dropdownSelectFields"/>
          </vs-select>
          <date-picker
            v-else-if="customField.type === 'date' && (customField.editableByCustomer || customField.requiredToSubmit)"
            v-validate="{ required: customField.requiredToSubmit ? true : false }"
            :name="`${customField.label}`"
            v-model="customField.value"
            lang="en"
            type="date"
            placeholder="DD/MM/YYYY"
            input-class="w-full datepicker-input w-full"
            format="DD/MM/YYYY"
            @change="assignFieldValue(customField._id, customField.value)"
            :disabled-days="disabledDate(customField)"
          >
          </date-picker>
          <p class="bg-neutral-50 font-light leading-tight px-2 pb-1" v-else>
            <span v-if="customField.type === 'currency'">$ {{ addZeroes(customField.value) }}</span>
            <span v-else-if="customField.type === 'number'">{{ formatNumber(customField.value) }}</span>
            <span v-else-if="customField.type === 'date'">{{ pfFormatDate(customField.value) }}</span>
            <span v-else>{{ customField.value }}</span>
          </p>
          <span class="text-danger text-sm" v-show="errors.has(`${customField.label}`)">{{ errors.first(`${customField.label}`) }}</span>
        </div>

        <div
          :key="key + 0.1"
          class="add-info"
          v-if="customField.type == 'file' && (customField.editableByCustomer || customField.requiredToSubmit || (customField.displayToCustomer && Object.keys(customField.value).length > 0))">
          <label style="color: #595959" :class="errors.has(`${customField.label}`) ? 'text-xs text-danger' : 'text-xs font-light'">
            {{ customField.label }}
            <span v-if="customField.requiredToSubmit" class="mid-blue">*</span>
            <span v-if="customField.description">
              <vs-tooltip :text="customField.description" position="top" class="icon-tooltip">
                <img class="icon-control" :src="tooltip"  alt="tooltip" />
              </vs-tooltip>
            </span>
          </label>
          <div class="addFile" v-if="showPreview(key)">
            <S3FileGenerator class="text-sm" v-if="customField.value" :document="customField.value" :key="customField.value"/>
            <a class="" v-if="customField.selectedFile">{{ customField.selectedFile.name }}</a>
            <div class="fileAction" v-if="customField.editableByCustomer || customField.requiredToSubmit">
              <svg
                class="ml-auto"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                @click="clearPreview(key, customField)">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1.66675 4.99992C1.66675 4.53968 2.03984 4.16658 2.50008 4.16658H17.5001C17.9603 4.16658 18.3334 4.53968 18.3334 4.99992C18.3334 5.46016 17.9603 5.83325 17.5001 5.83325H2.50008C2.03984 5.83325 1.66675 5.46016 1.66675 4.99992Z"
                  fill="#757575"/>
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8.33342 2.49992C8.1124 2.49992 7.90044 2.58772 7.74416 2.744C7.58788 2.90028 7.50008 3.11224 7.50008 3.33325V4.16658H12.5001V3.33325C12.5001 3.11224 12.4123 2.90028 12.256 2.744C12.0997 2.58772 11.8878 2.49992 11.6667 2.49992H8.33342ZM14.1667 4.16658V3.33325C14.1667 2.67021 13.9034 2.03433 13.4345 1.56548C12.9657 1.09664 12.3298 0.833252 11.6667 0.833252H8.33342C7.67037 0.833252 7.03449 1.09664 6.56565 1.56548C6.09681 2.03433 5.83342 2.67021 5.83342 3.33325V4.16658H4.16675C3.70651 4.16658 3.33341 4.53968 3.33341 4.99992V16.6666C3.33341 17.3296 3.59681 17.9655 4.06565 18.4344C4.53449 18.9032 5.17037 19.1666 5.83342 19.1666H14.1667C14.8298 19.1666 15.4657 18.9032 15.9345 18.4344C16.4034 17.9655 16.6667 17.3296 16.6667 16.6666V4.99992C16.6667 4.53968 16.2937 4.16658 15.8334 4.16658H14.1667ZM5.00008 5.83325V16.6666C5.00008 16.8876 5.08788 17.0996 5.24416 17.2558C5.40044 17.4121 5.6124 17.4999 5.83342 17.4999H14.1667C14.3878 17.4999 14.5997 17.4121 14.756 17.2558C14.9123 17.0996 15.0001 16.8876 15.0001 16.6666V5.83325H5.00008Z"
                  fill="#757575"/>
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8.33342 8.33325C8.79365 8.33325 9.16675 8.70635 9.16675 9.16658V14.1666C9.16675 14.6268 8.79365 14.9999 8.33342 14.9999C7.87318 14.9999 7.50008 14.6268 7.50008 14.1666V9.16658C7.50008 8.70635 7.87318 8.33325 8.33342 8.33325Z"
                  fill="#757575"/>
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M11.6667 8.33325C12.127 8.33325 12.5001 8.70635 12.5001 9.16658V14.1666C12.5001 14.6268 12.127 14.9999 11.6667 14.9999C11.2065 14.9999 10.8334 14.6268 10.8334 14.1666V9.16658C10.8334 8.70635 11.2065 8.33325 11.6667 8.33325Z"
                  fill="#757575"/>
              </svg>
            </div>
          </div>
          <input
            type="file"
            class="hidden"
            v-validate="{ required: customField.requiredToSubmit && Object.keys(customField.value).length == 0 && !customField.selectedFile ? true : false, size: maxFileSize * 1024 }"
            :name="customField.label"
            accept=".pdf"
            :ref="`uploadImgInputRef${key}`"
            @change="updateCurrImg($event, key, customField)"/>
          <div class="input-file" v-if="!showPreview(key)">
            <vs-button
              v-if="customField.editableByCustomer"
             type="flat"
              icon="icon-upload"
              icon-pack="feather"
              icon-size="small"
              @click="uploadImgInput(key)"
              >Upload file</vs-button>
          </div>
          <div class="text-danger text-sm" v-show="errors.has(`${customField.label}`)">
            {{ errors.first(`${customField.label}`) }}
          </div>
        </div>
        <div class="w-full" :key="key + 0.7" v-if="customField.type==='address' && (customField.editableByCustomer || customField.requiredToSubmit)">
          <label v-if="customField.isExpanded" style="color: #595959" :class="errors.has(`${customField.label}`) ? 'text-xs text-danger' : 'text-xs font-light'">
            {{ customField.label }}
            <span v-if="customField.requiredToSubmit" class="mid-blue">*</span>
            <span v-if="customField.description">
              <vs-tooltip :text="customField.description" position="top" class="icon-tooltip">
                <img class="icon-control" :src="tooltip" alt="tooltip" />
              </vs-tooltip>
            </span>
          </label>
          <label v-else
            style="color: #595959"
            :class="errors.has(`${customField.label}`) ? 'text-sm md:text-base text-danger input-label' : 'text-sm md:text-base font-light input-label'">
            {{ customField.label }}
            <span v-if="customField.requiredToSubmit" class="mid-blue">*</span>
            <span v-if="customField.description">
              <vs-tooltip :text="customField.description" position="top" class="icon-tooltip">
                <img class="icon-control" :src="tooltip" alt="tooltip" />
              </vs-tooltip>
            </span>
          </label>
          <vs-row>
            <vs-col vs-w="12" class="custom-input-field" v-if="!customField.isExpanded">
              <vue-google-autocomplete
                :ref="`${customField._id}`"
                :name="`${customField.label}`"
                :key="customField._id"
                style="left: unset"
                :id="customField._id"
                :value="productAddressFieldArray[customField._id].rawAddress"
                classname="vs-input--input custom-w input-label w-full"
                v-on:placechanged="getAddressForProductField"
                :country="['au']"
                placeholder="Start typing your address"
                @blur="handleClearAddress(customField._id)"
                >
              </vue-google-autocomplete>
            </vs-col>
            <vs-button type="flat" :key="key + 0.5" class="mt-2" @click="toggleExpand(key, customField._id)" v-show="!customField.isExpanded" size="small">Enter manually</vs-button>
            <vs-button type="flat" :key="key + 0.8" class="mt-2 mb-6" @click="toggleExpand(key, customField._id)" v-show="customField.isExpanded" size="small">Back to auto-complete</vs-button>
          </vs-row>
          <vs-row class="mb-6" v-if="customField.isExpanded">
            <vs-col vs-w="12" class="pr-xs-0">
              <label style="color: #050540;" class="text-sm md:text-base font-light w-full input-label">Unit</label>
              <vs-input
                class="w-full custom-w mob-w"
                v-model="productAddressFieldArray[customField._id].unit_number"
                :name="`unit_number[${key}]`"
                data-vv-as="unit number"
                @change="updateAddress(customField._id)"
                />
              <span class="text-danger text-sm" v-show="errors.has(`unit_number[${key}]`)">
                {{ errors.first(`unit_number[${key}]`) }}
              </span>
            </vs-col>
          </vs-row>
          <vs-row class="mb-6" v-if="customField.isExpanded">
            <vs-col vs-w="12" class="pr-xs-0">
              <label style="color: #050540;" class="text-sm md:text-base font-light w-full input-label">Street address</label>
              <vs-input
                class="w-full custom-w add-street mob-w"
                v-model="productAddressFieldArray[customField._id].street_name"
                :name="`street_name[${key}]`"
                v-validate="{required: customField.requiredToSubmit ? true : false}"
                data-vv-as="street name"
                @change="updateAddress(customField._id)"
                />
              <span class="text-danger text-sm" v-show="errors.has(`street_name[${key}]`)">{{ errors.first(`street_name[${key}]`) }}</span>
            </vs-col>
          </vs-row>
          <vs-row class="mb-6" v-if="customField.isExpanded">
            <vs-col vs-w="12" class="pr-xs-0">
              <label class="text-sm md:text-base font-light input-label">Suburb</label>
              <vs-input class="w-full custom-w mob-w-suburb" v-model="productAddressFieldArray[customField._id].suburb" :name="`suburb[${key}]`"
                v-validate="{required: customField.requiredToSubmit ? true : false}"
                data-vv-as="suburb"
                @change="updateAddress(customField._id)"
                 />
              <span class="text-danger text-sm" v-show="errors.has(`suburb[${key}]`)">{{ errors.first(`suburb[${key}]`) }}</span>
            </vs-col>
          </vs-row>
          <div class="grid gap-x-4 md:gap-x-8 grid-cols-2 add-info-force" v-if="customField.isExpanded">
            <div class="stateField">
              <label class="w-full text-sm md:text-base font-light input-label">State</label>
              <div class="input-container">
                <vs-select
                  autocomplete
                  v-model="productAddressFieldArray[customField._id].state"
                  class="w-full m-0 custom-w rounded-none"
                  :name="`state[${key}]`"
                  v-validate="{required: customField.requiredToSubmit ? true : false}"
                  placeholder="Select state"
                  data-vv-as="state"
                  @change="updateAddress(customField._id)"
                  >
                  <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item, index) in states"></vs-select-item>
                </vs-select>
              </div>
                <span class="text-danger text-sm" v-show="errors.has(`state[${key}]`)">
                  {{ errors.first(`state[${key}]`) }}
                </span>
            </div>

            <div class="postcodeField">
              <label class="text-sm md:text-base font-light input-label">Postcode</label>
              <vs-input maxlength="4" v-model="productAddressFieldArray[customField._id].postcode" :name="`postcode[${key}]`"
              data-vv-as="post code" v-validate="{required: customField.requiredToSubmit ? true : false}" onkeypress="return /[0-9]/i.test(event.key)"
              @change="updateAddress(customField._id)" />
              <span class="text-danger text-sm" v-show="errors.has(`postcode[${key}]`)">{{ errors.first(`postcode[${key}]`) }}</span>
            </div>
          </div>
          <div class="mb-2" v-if="errors.has(`${customField.label}`)">
            <span class="text-danger text-sm" v-show="errors.has(`${customField.label}`)">{{ errors.first(`${customField.label}`) }}</span>
          </div>
        </div>
        <div class="add-info" :key="key + 0.2" v-else-if="customField.type === 'address' && customField.displayToCustomer && Object.keys(customField.value).length > 0">
          <label style="color: #050540" :class="errors.has(`${customField.label}`) ? 'text-sm md:text-base text-danger input-label' : 'text-sm md:text-base font-light input-label'">
            {{ customField.label }}
          </label>
          <p class="bg-neutral-50 font-light leading-tight px-2 pb-1">{{ productAddressFieldArray[customField._id].rawAddress }}</p>
        </div>
      </template>
    </div>
    <div class="flex justify-center mt-14">
      <vs-button v-if="true" @click="startValidating" color="primary" size="large" class="w-full max-w-400">Continue to: {{ buttonText }}</vs-button>
    </div>
  </div>
</template>

<script>
  import { mapActions } from "vuex";
  import { Money } from "v-money";
  import { TheMask } from "vue-the-mask";
  import DatePicker from "vue2-datepicker";
  import moment from "moment";
  import VueGoogleAutocomplete from "vue-google-autocomplete";

  import S3ImageGenerator from "@/views/components/S3ImageGenerator";
  import S3FileGenerator from "@/views/components/S3FileGenerator";

  export default {
    props: [
      "buttonText",
      "defaultCustomFields",
      "formData",
      "inputArray",
      "inputArrayFiltered",
      "paymentRequest",
      "productAddressFieldArray",
      "requestOption",
      "selectedPlan",
      "states",
      "tempArray"
    ],

    components: {
      DatePicker,
      Money,
      S3FileGenerator,
      S3ImageGenerator,
      TheMask,
      VueGoogleAutocomplete
    },

    data() {
      return {
        maxFileSize: process.env.VUE_APP_FILE_MAX_SIZE_MB,
        moneyMask: {
          thousands: ",",
          prefix: "$ ",
          suffix: "",
          precision: 2,
          masked: false,
        },
      }
    },

    methods: {
      ...mapActions("payment", ["updateRequiredFields"]),

      disabledDate(data) {
        return (date) => this.disableSpecificDays(date, data);
      },

      disableSpecificDays(date, data) {
        if (!data || !data.validationWhenSendingRequests || !data.validationWhenSendingRequests.length) {
          return false;
        }

        const inFutureDateValidation = data.validationWhenSendingRequests.find(item => item.dateMustBe == "IN_FUTURE");

        if (!inFutureDateValidation) {
          return false;
        }

        const today = new Date();
        let dateHere = new Date();

        dateHere.setDate(dateHere.getDate() + parseInt(inFutureDateValidation.noOfDays));

        if (inFutureDateValidation.additionalRule == "LESS_THAN") {
          return date < today || date > dateHere;
        } else if (inFutureDateValidation.additionalRule == "MORE_THAN") {
          return date < dateHere;
        } else {
          return date < today;
        }
      },

      addZeroes(num) {
        num = String(num);
        return Number(num).toLocaleString(undefined, {
          minimumFractionDigits: 2,
        });
      },

      isNumber($event) {
        let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
        if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
          $event.preventDefault();
        }
      },

      formatNumber(num) {
        num = String(num);
        const dec = num.split(".")[1];
        return Number(num).toLocaleString(undefined, {minimumFractionDigits: 0});
      },

      pfFormatDate(date) {
        return moment(date).format("DD/MM/YYYY");
      },

      showPreview(key) {
        return (
          (
            this.inputArrayFiltered[key].hasOwnProperty('value') &&
            typeof this.inputArrayFiltered[key].value === 'string' &&
            this.inputArrayFiltered[key].value.length > 0
          )
          ||
          (
            this.inputArrayFiltered[key].hasOwnProperty('selectedFile') &&
            typeof this.inputArrayFiltered[key].selectedFile === 'object'
          )
        )
      },

      getAddressForProductField: function (addressData, placeResultData, _id) {
        let addressField = { ...this.productAddressFieldArray[_id] };

        if (placeResultData) {
          addressField.rawAddress = placeResultData.formatted_address;
          const addressIndex = this.inputArrayFiltered.findIndex((item) => item._id == _id);

          if (addressIndex >= 0) {
            this.inputArrayFiltered[addressIndex].value = placeResultData.formatted_address;
          }
        }

        if (addressData.street_number) {
          addressField.street_number = addressData.street_number;
        }

        if (addressData.postal_code) {
          addressField.postcode = addressData.postal_code;
        }

        if (placeResultData.vicinity) {
          addressField.suburb = placeResultData.vicinity;
        }

        if (addressData.administrative_area_level_1) {
          addressField.state = addressData.administrative_area_level_1;
        }

        if (addressData.route) {
          let street_address = "";
          for (const address of placeResultData.address_components) {
            if (address.types.includes("street_number")) {
              street_address += address.short_name + " ";
            }

            if (address.types.includes("route")) {
              street_address += address.long_name;
            }
          }
          addressField.street_name = street_address;
        }

        this.productAddressFieldArray[_id] = addressField;
        this.clearError(_id);
      },

      clearError(id) {
        const productField = this.inputArrayFiltered.filter((item) => item._id == id);

        if (productField.length && this.errors.has(productField[0].label)) {
          this.errors.remove(productField[0].label);
        }
      },

      assignFieldValue(id, value) {
        this.tempArray[id] = value;
      },

      clearPreview(key, fieldRow) {
        if (fieldRow.hasOwnProperty('value') && fieldRow.value.length > 0) {
          fieldRow.value = "";
        }
        this.$refs[`uploadImgInputRef${key}`][0].value = "";

        if (this.inputArrayFiltered[key].hasOwnProperty("selectedFile") && typeof this.inputArrayFiltered[key].selectedFile === 'object') {
          this.$delete(this.inputArrayFiltered[key], 'selectedFile');
        }
        if (this.errors.has(fieldRow.label)) {
          this.errors.remove(fieldRow.label);
        }
      },

      uploadImgInput(key) {
        this.$refs[`uploadImgInputRef${key}`][0].click();
      },

      updateCurrImg(input, key, fieldRow) {
        let file = input.target.files[0];
        fieldRow.selectedFile = file;
        this.tempArray[fieldRow._id] = file;
      },

      async startValidating() {
        const valid = await this.$validator.validate();

        if (valid) {
          this.formData.customFields = this.defaultCustomFields.filter((item) => { return item.fieldType === "custom"; });

          const paymentFormData = new FormData();
          const fileInCustomFields = this.formData.customFields.filter((field) => { return field.type === "file" && field.hasOwnProperty("selectedFile"); });

          if (fileInCustomFields.length > 0) {
            for (let index = 0; index < fileInCustomFields.length; index++) {
              paymentFormData.append(
                "customFiles",
                fileInCustomFields[index].selectedFile,
                `${fileInCustomFields[index].label
                  .split(" ")
                  .join("-")
                  .split("/")
                  .join("-")}@@${fileInCustomFields[index].selectedFile.name}`
              );
            }
          }
          this.formData.productCustomFields = this.defaultCustomFields.filter((item) => { return item.fieldType === "product"; });

          const fileInProductFields = this.formData.productCustomFields.filter(
            (field) => {
              return field.type === "file" && field.hasOwnProperty("selectedFile");
            }
          );

          if (fileInProductFields.length > 0) {
            for (let index = 0; index < fileInProductFields.length; index++) {
              paymentFormData.append(
                "productFiles",
                fileInProductFields[index].selectedFile,
                `${fileInProductFields[index].label
                  .split(" ")
                  .join("-")
                  .split("/")
                  .join("-")}@@${fileInProductFields[index].selectedFile.name}`
              );
            }
          }

          this.formData.productCustomFieldsNotViewable = this.productCustomFieldsNotViewable;

          if (this.formData.productCustomFields.length > 0) {
            // stringify all address fileds and add them to value
            this.formData.productCustomFields
              .filter((el) => el.type === "address")
              .map((el) => {
                if (el.requiredToSubmit && !Object.keys(this.productAddressFieldArray[el._id].rawAddress).length) {
                  this.errors.add({
                    field: `${el.label}`,
                    msg: `The ${el.label} field is required`
                  });
                }
                el.value = JSON.stringify(this.productAddressFieldArray[el._id]);

                return el;
              });
            this.formData.productCustomFields
              .filter((el) => el.type === "date")
              .map((el) => {
                el.value = el.value ? moment(el.value).format("YYYY-MM-DD") : ""
                return el;
              });
          }

          if (this.errors.items.length) {
            return false;
          }

          if (this.formData.customFields.length > 0) {
            this.formData.customFields.filter((el) => el.type === "date")
              .map((el) => {
                el.value = el.value ? moment(el.value).format("YYYY-MM-DD") : ""
                return el;
              });
          }

          let mappedFields = {
            customFields: this.formData.customFields
          }

          if (this.requestOption === "pay-later") {
            mappedFields.selectedPaymentPlan = this.formData.selectedPaymentPlan;
            mappedFields.productCustomFields = this.formData.productCustomFields;
          }

          paymentFormData.append("formData", JSON.stringify(mappedFields));

          let obj = {
            paymentRequestId: this.formData.paymentRequestId,
            data: paymentFormData,
            config: {
              header: {
                "Content-Type": "application/json",
              },
            },
          };

          this.$vs.loading();

          this.updateRequiredFields(obj).then(result => {
            this.$vs.loading.close();

            if (result) {
              this.$emit("requiredInformationComplete");
            }
          }).catch(ex => {
            this.$vs.loading.close();

            if (ex.status == 422)  {
              ex.data.data.forEach(item => {
                if (!this.errors.items.find(error => error.field == item.field)) {
                  this.errors.add(item);
                }
              });
            }
          })
        }
      },

      toggleExpand(index, id) {
        this.$emit("toggleExpand", index);
        this.$emit("clearAddress", id);
        this.$forceUpdate();
      },

      updateAddress(id) {
        this.$emit("getRawAddress", id);
        this.clearError(id);
        this.$forceUpdate();
      },

      handleClearAddress(id) {
        const addressRef = this.$refs[id];
        const addressValue = addressRef[0].$refs.autocomplete.value;
        if (addressValue == "") {
          this.$emit("clearAddress", id);
        }
      }
    }
  }
</script>

