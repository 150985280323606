<template>
  <div class="md:col-start-3 md:col-end-11">
    <div class="flex justify-between">
      <h5>{{ product.dateLabel || "Date" }}</h5>
      <h5>{{ product.amountLabel || "Amount" }}</h5>
    </div>

    <hr class="mt-2.5 mb-4" />

    <div class="grid max-h-40 overflow-y-auto pr-1">
      <div v-for="(schedule, key) in transactions" :key="key" class="flex justify-between mb-1.5">
        <p>{{ key === 0 ? getInitialDate(schedule.date) : formatScheduleDate(schedule.date) }}</p>
        <p>$ {{ addZeroes(schedule.amount) }}</p>
      </div>
    </div>

    <hr class="my-6" />

    <div class="flex justify-between mb-6">
      <h3>{{ product.totalAmountLabel || "Total" }}</h3>
      <h3>$ {{ addZeroes(totalAmount) }}</h3>
    </div>
    <p class="text-ancillary" v-if="product.feeText && product.feeModel === 'MSF_ADDED'">{{ parseContent(product.feeText) }}</p>
  </div>
</template>

<script>
  import moment from "moment";

  export default {
    props: ["feeAmount", "product", "totalAmount", "transactions"],

    methods: {
      addZeroes(num) {
        num = String(num);
        return Number(num).toLocaleString(undefined, {
          minimumFractionDigits: 2,
        });
      },

      formatScheduleDate(date) {
        return moment(date).format("DD MMM YYYY");
      },

      parseContent(data) {
        if (!data) {
          return "";
        }

        data = data.split("{{Fee %}}").join(this.product.msf + "%");
        data = data.split("{{Fee $ amount}}").join(" $"+this.feeAmount);

        return data;
      },

      getInitialDate(date) {
        return moment(moment().format("YYYY-MM-DD")).isSame(date) ? "Today" : this.formatScheduleDate(date);
      }
    }
  }
</script>