<template>
  <div class="md:col-start-3 md:col-end-11 bg-container-80">
    <p class="text-body mb-10">Available payment methods and corresponding transaction fees are listed below.</p>
    <div class="flex justify-between">
      <h5 class="w-185">Payment method</h5>
      <h5 class="w-12">Fee</h5>
      <h5>Total</h5>
    </div>

    <hr class="mt-2.5 mb-6" />

    <div class="grid gap-y-3">
      <div class="flex items-center justify-between" :key="index+0.9" v-for="(card, index) in cardOptions">
        <div class="flex items-center gap-x-4 w-200">
          <img v-if="card.card.slug == 'visa'" :src="visaCard" alt="visa" />
          <img v-else-if="card.card.slug == 'mastercard'" :src="masterCard" alt="masterCard" />
          <img v-else-if="card.card.slug == 'amex'" :src="amexCard" alt="amex" />
          <img v-else-if="card.card.slug == 'diners'" :src="dinersClubCard" alt="diners-club" />
          <img v-else-if="card.card.slug == 'unionpay'" :src="unionpayCard" alt="unionpay" />
          <p>{{ card.title }}</p>
        </div>
        <p class="w-12">{{ `${card.surcharge}%` }}</p>
        <p>$ {{ calculate(card.surcharge) }}</p>
      </div>
      <div class="flex items-center justify-between" v-if="eftEnabled">
        <div class="flex items-center gap-x-4 w-200">
          <img :src="bankTransfer" alt="bankTransfer" />
          <p>Bank transfer</p>
        </div>
        <p class="w-12">Free</p>
        <p>$ {{ addZeroes(amount) }}</p>
      </div>
    </div>
  </div>
</template>

<script>
  import S3ImageGenerator from "@/views/components/S3ImageGenerator";

  const amexCard = require("@/assets/images/cards/amex.svg");
  const bankTransfer = require("@/assets/images/cards/banktransfer.svg");
  const dinersClubCard = require("@/assets/images/cards/dinersclub.svg");
  const directDebitCard = require("@/assets/images/cards/directdebit.svg");
  const masterCard = require("@/assets/images/cards/mastercard.svg");
  const unionpayCard = require("@/assets/images/cards/unionpay.svg");
  const visaCard = require("@/assets/images/cards/visa.svg");

  export default {
    props: ["amount", "cardOptions", "eftEnabled"],

    components: {
      S3ImageGenerator
    },

    data() {
      return {
        amexCard: amexCard,
        bankTransfer: bankTransfer,
        dinersClubCard: dinersClubCard,
        directDebitCard: directDebitCard,
        masterCard: masterCard,
        unionpayCard: unionpayCard,
        visaCard: visaCard,
      }
    },

    methods: {
      calculate(surcharge) {
        const fee = ((this.amount / 100) * surcharge).toFixed(2);
        return this.addZeroes(parseFloat(fee) + parseFloat(this.amount));
      },

      addZeroes(num) {
        num = String(num);

        return Number(num).toLocaleString(undefined, {
          minimumFractionDigits: 2,
        });
      },
    }
  }
</script>

