<template>
  <div class="ds-wrapper mt-3">
    <h2 class="text-center text-primary uppercase mb-6">your identity</h2>
    <p class="text-center text-body-dark mb-10" v-html="selectedPlan.identityText"></p>

    <!-- Enter manually input field -->
    <div class="grid gap-y-6">
      <div>
        <label class="input-label" v-if="!isExpanded">Address</label>
        <div v-if="!isExpanded" class="custom-input-field">
          <vue-google-autocomplete
            ref="address"
            v-on:placechanged="getAddressData"
            id="map"
            classname="w-full custom-w"
            :country="['au']"
            placeholder="Start typing your address"
            @change="addressChange"
            @focus="setAttributeAutocomplete"
          ></vue-google-autocomplete>
        </div>
        <vs-button type="flat" class="mt-2" @click="toggleExpand" v-show="isExpanded" size="small">Back to auto-complete</vs-button>
        <vs-button type="flat" class="mt-2" @click="toggleExpand" v-show="!isExpanded" size="small">Enter manually</vs-button>
      </div>
      <div v-if="isExpanded">
        <div class="grid gap-y-6">
          <div>
            <vs-input label="Unit" v-model.trim="formData.street_number" @change="updateAddress('street_number')" autocomplete="new-password" />
          </div>
          <div>
            <vs-input label="Street address" v-model.trim="formData.street_name" name="street_name" v-validate="'required'" @change="updateAddress('street_name')" autocomplete="new-password" />
            <span class="text-danger text-sm" v-show="errors.has('street_name')">{{ errors.first("street_name") }}</span>
          </div>
          <div>
            <vs-input label="Suburb" v-model.trim="formData.suburb" name="suburb" v-validate="'required'" @change="updateAddress('suburb')" autocomplete="new-password" />
            <span class="text-danger text-sm" v-show="errors.has('suburb')">{{ errors.first("suburb") }}</span>
          </div>
          <div class="flex flex-row gap-x-6">
            <div class="w-1/2">
              <label class="input-label">State</label>
              <vs-select v-model.trim="formData.state" class="custom-w full-width-select rounded-none" name="state" key="state" v-validate="'required'" @change="updateAddress('state')">
                <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item, index) in states" placeholder="Select state"></vs-select-item>
              </vs-select>
              <span class="text-danger text-sm" v-show="errors.has('state')">{{ errors.first("state") }}</span>
            </div>
            <div class="w-1/2">
              <vs-input label="Postcode" maxlength="4" v-model.trim="formData.postcode" name="postcode" v-validate="'required'" @change="updateAddress('postcode')" onkeypress="return /[0-9]/i.test(event.key)" autocomplete="new-password" />
              <span class="text-danger text-sm" v-show="errors.has('postcode')">{{ errors.first("postcode") }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr class="my-8" />

    <p class="text-body-dark mb-4">Australian identification document</p>
    <segmented-button :buttons="segmentButtons" @segmented-buttons="selectIdentityMethod" :size="'small'" class="mb-13" />
    <p class="text-body-dark mb-8">Enter the following information as it appears on your {{ formData.id_type == "DL" ? "driver licence" : "passport"}}.</p>
    <div class="grid gap-y-8 md:grid-cols-3 md:gap-x-10 mb-10">
      <div>
        <vs-input label="First name" name="first_name" placeholder="Required" v-model.trim="formData.first_name" v-validate="'required'" :danger="errors.has('first_name')" autocomplete="new-password" />
        <span class="text-danger text-sm" v-show="errors.has('first_name')">{{ errors.first("first_name") }}</span>
      </div>
      <div>
        <vs-input label="Middle name" name="middle_name" v-model.trim="formData.middle_name" placeholder="Optional" autocomplete="new-password" />
        <span class="text-danger text-sm" v-show="errors.has('middle_name')">{{ errors.first("middle_name") }}</span>
      </div>
      <div>
        <vs-input label="Last name" name="last_name" placeholder="Required" v-model.trim="formData.last_name" v-validate="'required'" :danger="errors.has('last_name')" autocomplete="new-password" />
        <span class="text-danger text-sm" v-show="errors.has('last_name')">{{ errors.first("last_name") }}</span>
      </div>
      <div>
        <div class="input-datepicker" :class="errors.has('date_of_birth') ? 'hasError' : ''">
          <label class="input-label">Date of birth</label>
          <date-picker
            input-class="datepicker-input w-full"
            v-model.trim="formData.date_of_birth"
            format="DD/MM/YYYY"
            valuetype="format"
            placeholder="DD/MM/YYYY - Required"
            name="date_of_birth"
            id="date_of_birth"
            v-validate="'required|18-years-age'"
            :disabled-days="(date) => date >= new Date()"
            lang="jp"
          ></date-picker>
          <span class="text-danger text-sm" v-show="errors.has('date_of_birth')">{{ errors.first("date_of_birth") }}</span>
        </div>
      </div>
    </div>
    <div class="grid gap-y-8 md:grid-cols-3 md:gap-x-10 mb-10">
      <template v-if="formData.id_type === 'DL'">
        <div id="state-div">
          <label class="input-label">State of issue</label>
          <vs-select v-model.trim="formData.licenseDetails.state" class="custom-w full-width-select rounded-none" name="license_state" key="license_state" v-validate="this.formData.id_type === 'DL' ? 'required' : ''" :danger="errors.has('license_state')" placeholder="Required">
            <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item, index) in states"></vs-select-item>
          </vs-select>
          <span class="text-danger text-sm" v-show="errors.has('license_state')">{{ errors.first("license_state") }}</span>
        </div>
        <template v-if="formData.licenseDetails.state">
          <div>
            <vs-input label="Licence number" placeholder="Required" v-model.trim="formData.licenseDetails.license_number" name="license_number" v-validate="formData.id_type === 'DL' ? 'required' : ''" key="license_number" :danger="errors.has('license_number')" autocomplete="new-password" />
            <span class="text-danger text-sm" v-show="errors.has('license_number')">{{ errors.first("license_number") }}</span>
          </div>
          <div>
            <div class="input-datepicker" :class="errors.has('license_expiry_date') ? 'hasError' : ''">
              <label class="input-label">Expiry date</label>
              <date-picker
                v-model.trim="formData.licenseDetails.expiry_date"
                placeholder="DD/MM/YYYY - Required"
                format="DD/MM/YYYY"
                valuetype="format"
                name="license_expiry_date"
                id="license_expiry_date"
                v-validate="this.formData.id_type === 'DL' ? 'required' : ''"
                key="license_expiry_date"
                :disabled-days="(date) => date <= new Date()"
                input-class="datepicker-input w-full"
                lang="jp"
              ></date-picker>
              <span class="text-danger text-sm" v-show="errors.has('license_expiry_date')">{{ errors.first("license_expiry_date") }}</span>
            </div>
          </div>
          <div :class="errors.has('license_card_number') ? 'input-icon-validate-danger' : ''">
            <label class="input-label" :class="errors.has('license_card_number') ? 'danger' : ''">Licence card number</label>
            <div>
              <vs-input
                placeholder="Required"
                class="w-full custom-w"
                v-model.trim="formData.licenseDetails.card_number"
                name="license_card_number"
                v-validate="licenseCardNumberValidation"
                :danger="errors.has('license_card_number')"
                autocomplete="new-password"
              />
              <span class="text-danger text-sm" v-show="errors.has('license_card_number')">{{ errors.first("license_card_number") +" " + licenseCardErrorText }}</span>
            </div>
          </div>

          <div class="bg-neutral-200 px-4 py-2 rounded-xl">
            <div class="flex gap-x-2">
              <svg width="32" height="26" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_2869_36143)">
                <path d="M3.125 10.5H5.125C5.125 11.05 4.675 11.5 4.125 11.5C3.575 11.5 3.125 11.05 3.125 10.5ZM2.125 10H6.125V9H2.125V10ZM7.875 5.25C7.875 7.16 6.545 8.18 5.99 8.5H2.26C1.705 8.18 0.375 7.16 0.375 5.25C0.375 3.18 2.055 1.5 4.125 1.5C6.195 1.5 7.875 3.18 7.875 5.25ZM6.875 5.25C6.875 3.735 5.64 2.5 4.125 2.5C2.61 2.5 1.375 3.735 1.375 5.25C1.375 6.485 2.12 7.195 2.55 7.5H5.7C6.13 7.195 6.875 6.485 6.875 5.25ZM10.31 4.185L9.625 4.5L10.31 4.815L10.625 5.5L10.94 4.815L11.625 4.5L10.94 4.185L10.625 3.5L10.31 4.185ZM9.125 3.5L9.595 2.47L10.625 2L9.595 1.53L9.125 0.5L8.655 1.53L7.625 2L8.655 2.47L9.125 3.5Z" fill="#4984E2"/>
                </g>
                <defs>
                <clipPath id="clip0_2869_36143">
                <rect width="12" height="12" fill="white"/>
                </clipPath>
                </defs>
              </svg>
              <div>
                <p>Licence card number is different to your licence number.</p>
                <a href="#" class="no-underline my-2 block" @click="handleCloseLicensePopup">Help me find it</a>
                <p>If you have trouble reading the card number on your licence, consider using an Australian passport as your ID document </p>
              </div>
            </div>
          </div>
        </template>
      </template>
      <template v-else>
        <div>
          <vs-input label="Passport number" placeholder="Required" v-model.trim="formData.passportDetails.passport_number" name="passport_number" v-validate="formData.id_type === 'PP' ? 'required' : ''" key="passport_number" :danger="errors.has('license_card_number')" autocomplete="new-password" />
          <span class="text-danger text-sm" v-show="errors.has('passport_number')">{{ errors.first("passport_number") }}</span>
        </div>
        <div>
          <div class="input-datepicker" :class="errors.has('passport_issue_date') ? 'hasError' : ''">
            <label class="input-label">Issue Date</label>
            <date-picker
              input-class="datepicker-input w-full"
              v-model.trim="formData.passportDetails.issue_date"
              placeholder="DD/MM/YYYY - Required"
              format="DD/MM/YYYY"
              valuetype="format"
              name="passport_issue_date"
              id="passport_issue_date"
              v-validate="this.formData.id_type === 'PP' ? 'required' : ''"
              key="passport_issue_date"
              :disabled-days="(date) => date >= new Date()"
              lang="jp"
            ></date-picker>
            <span class="text-danger text-sm" v-show="errors.has('passport_issue_date')" >{{ errors.first("passport_issue_date") }}</span>
          </div>
        </div>
        <div>
          <div class="input-datepicker" :class="errors.has('passport_issue_date') ? 'hasError' : ''">
            <label class="input-label">Expiry date</label>
            <date-picker
              v-model.trim="formData.passportDetails.expiry_date"
              placeholder="DD/MM/YYYY - Required"
              format="DD/MM/YYYY"
              valuetype="format"
              name="passport_expiry_date"
              id="passport_expiry_date"
              v-validate="this.formData.id_type === 'PP' ? 'required' : ''"
              key="passport_expiry_date"
              :disabled-days="(date) => date <= new Date()"
              input-class="datepicker-input w-full"
              lang="jp"
            ></date-picker>
            <span class="text-danger text-sm" v-show="errors.has('passport_expiry_date')" >{{ errors.first("passport_expiry_date") }}</span>
          </div>
        </div>
      </template>
    </div>

    <template v-if="(formData.id_type == 'DL' && formData.licenseDetails.state) || formData.id_type == 'PP'">
      <div class="flex gap-x-4 cursor-pointer">
        <vs-checkbox v-model="authorized" name="authorized" class="items-start"></vs-checkbox>
        <p class="text-body" @click="authorized = !authorized">I am authorised to provide the above information. I consent to this being checked with the document issuer/official record holder via third parties for the purpose of confirming my identity</p>
      </div>
      <div class="flex justify-center mt-14" v-if="idMatrix.verifyCount > 0 && idMatrix.verifyCount < 4 && !hideIdErrorBlock">
        <vs-alert color="warning" icon="error_outline">
          <p>We're unable to verify your identity using the information provided, please check the details entered or try a different document. If we're unable to verify your ID after multiple attempts, you won't be able to proceed with this payment option.</p>
        </vs-alert>
      </div>

      <div class="flex justify-center mt-14 info-tooltip" v-if="showTooltip && disableVerify">
        <div class="bg-neutral-200 px-8 py-4 rounded-xl box-shadow">
          <p class="text-primary text-ancillary">Please review your information before proceeding</p>
        </div>
      </div>

      <div class="flex justify-center mt-14" @mouseover="showTooltip = true" @mouseleave="showTooltip = false">
        <vs-button color="primary" size="large" class="w-full max-w-400" @click="startValidating" :disabled="disableVerify">Continue to: {{ getButtonText }}</vs-button>
      </div>
    </template>

    <!-- LICENSE CARD POPUP -->
    <license-card :state="formData.licenseDetails.state" :license-popup="licensePopup" @handleCloseLicensePopup="handleCloseLicensePopup" />
  </div>
</template>

<script>
  import VueGoogleAutocomplete from "vue-google-autocomplete";
  import DatePicker from "vue2-datepicker";

  import SegmentedButton from "./SegmentedButtons.vue";
  import LicenseCard from "./LicenseCard";
  const questionIcon = require("@/assets/images/toolbox.svg");
  import ToolTipIcon from "./ToolTipIcon";
  import { AlertCircleIcon } from "vue-feather-icons";

  export default {
    props: [
      "addressData",
      "authorized_to_use",
      "checkDisbursementExist",
      "disableSubmit",
      "formData",
      "hideIdErrorBlock",
      "idMatrix",
      "idMatrixValidations",
      "index",
      "page_type",
      "privacy",
      "selectedPlan",
      "states",
    ],

    data() {
      return {
        appUrl: process.env.VUE_APP_ROOT_API,
        authorized: false,
        notRequiredStates: [ // TODO: this will require revision in 2023
          "QLD",
          "VIC"
        ],
        isExpanded: false,
        segmentButtons: [
          {
            label: "Driver licence",
            active: true,
            value: "DL"
          },
          {
            label: "Passport",
            active: false,
            value: "PP"
          }
        ],
        questionIcon: questionIcon,
        licensePopup: false,
        showTooltip: false,
      }
    },

    components: {
      AlertCircleIcon,
      DatePicker,
      SegmentedButton,
      ToolTipIcon,
      VueGoogleAutocomplete,
      LicenseCard,
    },

    methods: {
      isMobile() {
        return /(android|webos|iphone|ipad|ipod|blackberry|windows phone)/.test(navigator.platform.toLowerCase());
      },

      handleCloseLicensePopup() {
        if (this.isMobile()) {
          window.open(`${process.env.VUE_APP_ROOT_API}payment/license?state=${this.formData.licenseDetails.state}`, "_blank");
        } else {
          this.licensePopup = !this.licensePopup;
        }
      },

      addressChange(val) {
        if (val) {
          if (this.errors.has("rawAddress")) {
            this.errors.remove("rawAddress");
          }
        }
      },

      toggleExpand() {
        this.isExpanded = !this.isExpanded;
        this.$emit("clearAddress");
      },

      getAddressData: function (addressData, placeResultData, _id) {
        if (this.errors.has("rawAddress")) {
          this.errors.remove("rawAddress");
        }

        this.$emit("changeAddress", addressData, placeResultData, _id);
      },

      selectIdentityMethod(identityMethod) {
        this.formData.id_type = identityMethod ? identityMethod.value : "";
      },

      async startValidating() {
        this.$validator.validateAll().then(async (result) => {
          if (result) {
            this.$emit("verifyId");
          }
        });
      },

      updateAddress(val) {
        this.$emit("getRawAddress", "formdata");
        this.addressChange(val);
      },

      setAttributeAutocomplete() {
        const autocompleteInput = document.getElementById("map");
        autocompleteInput.setAttribute("autocomplete", "new-password");
      }
    },

    computed: {
      getButtonText() {
        let buttonText = "";

        if (this.checkDisbursementExist) {
          buttonText = this.selectedPlan && this.selectedPlan.disbursement && this.selectedPlan.disbursement.sectionHeading ? this.selectedPlan.disbursement.sectionHeading : "Disbursement account";
        } else {
          buttonText = this.selectedPlan.paymentSectionHeading || "Payment method";
        }

        return buttonText;
      },

      disableVerify() {
        return (!this.authorized_to_use || this.idMatrix.verified || !this.validateForm || this.formData.id_type == "" || this.disableSubmit) ;
      },

      validateForm() {
        return !this.errors.any();
      },

      licenseCardNumberValidation() {
        let validation = "";

        switch (this.formData.licenseDetails.state) {
          case "ACT":
          case "QLD":
            validation = "required|alpha_num|length:10";
            break;
          case "NT":
            validation = "required|numeric|min:6|max:8";
            break;
          case "NSW":
            validation = "required|numeric|digits:10";
            break;
          case "SA":
          case "TAS":
            validation = "required|alpha_num|length:9";
            break;
          case "VIC":
            validation = "required|alpha_num|length:8";
            break;
          case "WA":
            validation = "required|alpha_num|min:8|max:10";
            break;
        }

        return validation;
      },

      licenseCardErrorText() {
        return ["ACT", "QLD", "SA", "TAS", "VIC"].includes(this.formData.licenseDetails.state) && this.errors.items.find(item => ["max", "min", "length"].includes(item.rule)) ? "characters" : "";
      }
    },

    watch: {
      licensePopup(val) {
        if (val) {
          document.body.classList.add("custom-popup");
        } else {
          const el = document.getElementById("state-div");
          this.$scrollTo(el);
          document.body.classList.remove("custom-popup");
        }
      },

      authorized(val) {
        this.$emit("idVerifyAuthorized", val);
      },

      idMatrixValidations(val) {
        if (val && val.length) {
          for (let i = 0; i < val.length; i++) {
            this.errors.add({
              field: Object.keys(val[i])[0],
              msg: Object.values(val[i])[0]
            });
          }
        }
      }
    },

    mounted() {
      this.formData.id_type = "DL";
    }
  }
  </script>

