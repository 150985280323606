<template>
  <div>
    <vs-card v-for="(product, key) in products" :key="key" class="pay-in-5 ds-card mb-6 no-padding" :class="{ 'with-out-banner': !product.marketingBanner, 'payment-one': product.marketingBanner }">
      <div slot="header" v-if="product.marketingBanner">
        <p class="ancillary uppercase" id="marketing-banner" v-html="product.marketingBannerText"></p>
      </div>
      <div>
        <div class="custom-padding">
          <h3 class="mb-4">{{ product.productName }}</h3>
          <p class="para-def-light-anc mb-6">{{ product.productText }}</p>
          <div class="sm:grid sm:grid-cols-12 sm:items-center">
            <div class="col-span-3 mb-6">
              <p class="text-primary text-sm light" v-if="product.productType === 'B2C_BNPL'">{{ `${product.numberOfPayments} ${product.paymentFrequency.toLowerCase()}`}} payments of</p>
              <p class="text-primary text-sm light" v-else>{{ `Due ${getMaturityDate(product.customPlanId)}`}}</p>
              <h2 class="display-2">${{ addZeroes(getFirstTransactionAmount(product.customPlanId)) }}</h2>
            </div>
            <vs-button @click="selectProduct(product._id)" :color="option.btnBackground ? 'primary' : ''" :type="!option.btnBackground ? 'border' : ''" size="large" class="w-full sm:col-start-10 sm:col-end-13">Select</vs-button>
          </div>
        </div>

        <div v-if="product.showPaymentDetail" class="bg-container-80 md:grid md:grid-cols-12 py-6 px-4 md:py-8 md:px-6">
          <template v-if="product.productType === 'B2C_BNPL'">
            <pay-later-summary
              :fee-amount="getFeeAmount(product.customPlanId)"
              :product="product"
              :transactions="getScheduledTransaction(product.customPlanId)"
              :total-amount="getTotalAmount(product.customPlanId)"
            />
          </template>
          <template v-else>
            <bnpo-summary
              :disbursement-amount="getDishbursementAmount(product.customPlanId)"
              :due-date="getMaturityDate(product.customPlanId)"
              :fee-amount="getFeeAmount(product.customPlanId)"
              :product="product"
              :total-amount="getTotalAmount(product.customPlanId)"
              :transactions="getScheduledTransaction(product.customPlanId)"
            />
          </template>
        </div>

        <div class="flex justify-between items-center px-8 py-3">
          <h3 class="text-body-dark ancillary uppercase">{{ product.showPaymentDetail ? "hide details" : "show details" }}</h3>
          <vs-icon type="flat" class="text-body-dark material-icons-outlined cursor-pointer" :icon="product.showPaymentDetail ? 'expand_less' : 'expand_more'" icon-size="large" @click="togglePaymentDetail(key)" />
        </div>
      </div>
    </vs-card>
  </div>
</template>

<script>
import moment from "moment";

import BnpoSummary from "./BnpoSummary.vue";
import PayLaterSummary from "./payLaterSummary.vue";
import payToday from "./payToday.vue";

export default {
  props: ["cardOptions", "products", "transactions"],

  components : {
    BnpoSummary,
    PayLaterSummary,
    payToday,
  },

  data() {
    return {
      showPaymentDetail: false,
      option: {
        cardHeader: true,
        cardBorder: true,
        btnBackground: true
      }
    };
  },

  methods: {
    addZeroes(num) {
      num = String(num);

      return Number(num).toLocaleString(undefined, {
        minimumFractionDigits: 2,
      });
    },

    togglePaymentDetail(index) {
      this.products[index].showPaymentDetail = !this.products[index].showPaymentDetail;
    },

    getTransactions(productId) {
      const plan = this.transactions.find(item => item.planId === productId);
      return plan ? plan.tansactionSchedule : [];
    },

    getScheduledTransaction(productId) {
      const transactions = this.getTransactions(productId);
      return transactions.filter(item => item.type === "Payment" && item.status == "Scheduled");
    },

    getFirstTransactionAmount(productId) {
      let transactions = this.getScheduledTransaction(productId);
      transactions = transactions.sort(function(first, second) { return first.amount - second.amount });
      return transactions && transactions.length ? transactions[transactions.length - 1].amount : 0.00;
    },

    getTotalAmount(productId) {
      const transactions = this.getTransactions(productId);
      const total = transactions.find(item => item.type === "Total");

      return total ? total.amount : null;
    },

    getFeeAmount(productId) {
      const transactions = this.getTransactions(productId);
      const total = transactions.find(item => item.type === "Fee" && item.subType === "Service Fee");

      return total ? this.addZeroes(total.amount) : null;
    },

    getDishbursementAmount(productId) {
      const transactions = this.getTransactions(productId);
      const total = transactions.find(item => item.type === "Disbursement");

      return total ? total.amount : null;
    },

    selectProduct(productId) {
      return this.$emit("selectProduct", productId);
    },

    getMaturityDate(productId) {
      const transactions = this.getScheduledTransaction(productId);

      return transactions && transactions.length ? this.formatScheduleDate(transactions[transactions.length - 1].date) : "";
    },

    formatScheduleDate(date) {
      return moment(date).format("DD MMM YYYY");
    },
  },

  mounted() {
    if (this.cardOptions) {
      this.option = this.cardOptions;
    }
  }
}
</script>

