<template>
  <div>
    <vs-card class="pay-in-5 ds-card mb-6 with-out-banner no-padding">
      <div>
        <div class="custom-padding">
          <h3 class="mb-4">Pay today</h3>
          <p class="para-def-light-anc mb-6">If you'd prefer to pay the full amount today, select this option.</p>
          <div class="sm:grid sm:grid-cols-12 sm:items-center">
            <div class="col-span-3 mb-6">
              <h2 class="display-2">${{ addZeroes(amount) }}</h2>
              <p>Plus transaction fees</p>
            </div>
            <vs-button @click="selectProduct('pay-now')" :color="option.btnBackground ? 'primary' : '' " :type="!option.btnBackground ? 'border' : '' " size="large" class="w-full sm:col-start-10 sm:col-end-13">Select</vs-button>
          </div>
        </div>
        <div v-if="showMore" class="bg-container-80 md:grid md:grid-cols-12 py-6 px-4 md:py-8 md:px-6">
          <pay-today :amount="amount" :card-options="cardOptions" :eft-enabled="eftEnabled" />
        </div>
        <div class="flex justify-between items-center px-8 py-3">
          <h3 class="text-body-dark ancillary uppercase">{{ showMore ? "hide details" : "show details" }}</h3>
          <vs-icon type="flat" class="text-body-dark material-icons-outlined cursor-pointer" :icon="showMore ? 'expand_less' : 'expand_more'" icon-size="large" @click="showMore = !showMore" />
        </div>
      </div>
    </vs-card>
  </div>
</template>

<script>
  import PayToday from "./payToday";
export default {
  components: {
    PayToday
  },
  props: ["amount", "cardOptions", "eftEnabled"],

  data() {
    return {
      showPaymentDetail: false,
      option: {
        cardHeader: true,
        cardBorder: true,
        btnBackground: true
      },
      showMore: false
    };
  },

  methods: {
    addZeroes(num) {
      num = String(num);

      return Number(num).toLocaleString(undefined, {
        minimumFractionDigits: 2,
      });
    },

    togglePaymentDetail() {
      this.showPaymentDetail = !this.showPaymentDetail;
    },

    selectProduct(val) {
      return this.$emit("selectProduct", val);
    },
  },

  mounted() {
    if (this.cardOptions) {
      this.option = this.cardOptions
    }
  }
}
</script>

