<template>
  <div class="md:col-start-3 md:col-end-11">
    <div class="text-center">
      <p class="text-ancillary">{{ product.message }}</p>
      <h4>{{ dueDate }}</h4>
    </div>
    <div class="text-center summary-border my-2.5">
      <hr class="max-w-420" />
    </div>
    <div class="flex flex-col items-center">
      <div class="flex justify-between items-center mb-2 w-full max-w-420">
        <p>{{ product.purchaseAmountLabel || "Purchase amount" }}</p>
        <p class="text-sm">$ {{ addZeroes(disbursementAmount) }}</p>
      </div>
      <div class="flex justify-between items-center w-full max-w-420">
        <p>{{ product.feeLabel || "Service fee" }}</p>
        <p class="text-sm">$ {{ feeAmount }}</p>
      </div>
    </div>
    <div class="text-center summary-border my-2.5">
      <hr class="max-w-420" />
    </div>
    <div class="flex flex-col items-center">
      <div class="flex justify-between items-center w-full max-w-420">
        <p class="text-primary">{{ product.totalAmountLabel || "Total" }}</p>
        <p class="text-primary">$ {{ addZeroes(totalAmount) }}</p>
      </div>
    </div>
    <p class="text-ancillary text-center mt-8" v-if="product.configurableFeeText">{{ product.configurableFeeText }}</p>
  </div>
</template>

<script>
export default {
  props: ["disbursementAmount", "dueDate", "feeAmount", "product", "totalAmount", "transactions"],

  methods: {
    addZeroes(num) {
      num = String(num);

      return Number(num).toLocaleString(undefined, {
        minimumFractionDigits: 2,
      });
    },
  }
}
</script>
