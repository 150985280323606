<template>
  <div class="bg-error-50 bg-border-container-danger p-4 rounded-xl">
    <div class="flex gap-x-4">
      <img class="custom-icon" :src="cancelIcon" />
      <div>
        <p class="text-gray-dark">{{ message }}</p>
      </div>
    </div>
  </div>
</template>

<script>
const CancelIcon = require("@/assets/images/cancel.svg");

export default {
  name: "errorMessage",
  components: {
    CancelIcon,
  },
  props: ["message"],
  data() {
    return {
      cancelIcon: CancelIcon,
    }
  },
}
</script>

