<template>
  <div class="segmented-buttons max-w-xxs">
    <vs-button v-for="(btn, key) in buttons" :key="key" type="segment" :class="btn.active ? 'active': ''" @click="handleButton(key)" :value="btn.value" :size="size">{{ btn.label }}</vs-button>
  </div>
</template>

<script>
  export default {
    props: ["buttons", "size"],

    methods:{
      handleButton(index) {
        this.buttons.forEach(item => item.active = false);
        this.buttons[index].active = true;
        this.$emit("segmented-buttons", this.buttons[index]);
      }
    },
  }
</script>
